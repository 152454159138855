import { createGlobalStyle } from 'styled-components';
import GothamBold from '../../static/fonts/Gotham-Bold.woff';
import GothamBoldSvg from '../../static/fonts/gothambold.svg#gothambold';
import GothamBoldEot from '../../static/fonts/gothambold.eot';
import GothamBoldTtf from '../../static/fonts/gothambold.ttf';
import GothamBook from '../../static/fonts/Gotham-Book.woff';
import GothamBookSvg from '../../static/fonts/gothambook.svg#gothambook';
import GothamBookEot from '../../static/fonts/gothambook.eot';
import GothamBookTtf from '../../static/fonts/gothambook.ttf';
import GothamLight from '../../static/fonts/Gotham-Light.woff';
import GothamLightEot from '../../static/fonts/gothamlight.eot';
import GothamLightSvg from '../../static/fonts/gothamlight.svg#gothamlight';
import GothamLightTtf from '../../static/fonts/gothamlight.ttf';
import GothamMedium from '../../static/fonts/Gotham-Medium.woff';
import GothamMediumSvg from '../../static/fonts/gothammedium.svg#gothammedium';
import GothamMediumEot from '../../static/fonts/gothammedium.eot';
import GothamMediumTtf from '../../static/fonts/gothammedium.ttf';
import GothamBlack from '../../static/fonts/Gotham-Black.woff';
import GothamBlack2 from '../../static/fonts/Gotham-Black.woff2';

const GlobalStyles = createGlobalStyle`
@font-face {
  font-family: "Gotham-Book";
  src: url(${GothamBook}) format('woff'),
  url(${GothamBookEot}),
    url(${GothamBookTtf}) format('truetype'),
    url(${GothamBookSvg}) format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Light";
  src: url(${GothamLight}) format('woff'),
  	url(${GothamLightEot}),
	url(${GothamLightTtf}) format('truetype'),
    url(${GothamLightSvg}) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gotham-Medium";
  src: url(${GothamMedium}) format('woff'),
  	url(${GothamMediumEot}),
    url(${GothamMediumTtf}) format('truetype'),
    url(${GothamMediumSvg}) format('svg');
font-weight: normal;
font-style: normal;
}
@font-face {
  font-family: "Gotham-Bold";
  src: url(${GothamBold}) format('woff'),
  url(${GothamBoldEot}),
  url(${GothamBoldTtf}) format('truetype'),
  url(${GothamBoldSvg}) format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Gotham-Black";
  src: url(${GothamBlack2}) format('woff2'),
  url(${GothamBlack}) format('woff');
  font-weight: normal;
  font-style: normal;
}
		
/*------------------------------
    Gen. GENERAL 
------------------------------*/
	
	* {
		box-sizing: border-box;		
		-box-sizing: border-box;
		-moz-box-sizing: border-box;
		-text-size-adjust: none;
		}
	
		body {
			margin: 0;
			padding: 0;
			background-color: #fff;
			background-repeat: no-repeat;
			background-position: top center;
			color: #666;
			font-family: "Gotham-Book", arial, sans-serif;
			font-size: 15px;
			line-height: 24px;
			letter-spacing: 0.02em;
		}

		img {
			border: 0;
			vertical-align: top;
		}

		h1 {
			margin: 0 0 35px 0;
			padding: 0;
			font-family: "Gotham-Light", arial, sans-serif;
			font-weight: normal !important;
			color: #00483A;
			font-size: 45px;
			line-height: 50px;
		}
		h2 {
			margin: 0 0 35px 0 !important;
			padding: 0;
			font-family: "Gotham-Light", arial, sans-serif;
			font-weight: normal !important;
			color: #008268;
			font-size: 32px;
			line-height: 38px;
		}
		h3 {
			margin: 0 0 35px 0;
			padding: 0;
			font-family: "Gotham-Light", arial, sans-serif;
			font-weight: normal !important;
			color: #008168;
			font-size: 26px;
			line-height: 35px;
		}
		h1 strong,
		h2 strong,
		h3 strong {
			font-family: "Gotham-Light", arial, sans-serif;
			font-weight: normal !important;
		}

		hr {
			height: 1px;
			border: none;
			background: #e5e5e5;
			margin: 50px 0;
			clear: both;
		}

		p {
			margin: 0 0 30px 0;
			padding: 0;
		}
		p.intro {
			font-size: 19px;
			line-height: 28px;
		}
		p.note {
			font-size: 12px;
			line-height: 18px;
		}

		strong {
			font-family: "Gotham-Bold", arial, sans-serif;
		}

		a:hover,
		a:active,
		a:focus,
		object {
			outline: none;
		}
		a {
			text-decoration: none;
			color: #eea200;
			webkit-transition: color 0.15s ease-out;
			-moz-transition: color 0.15s ease-out;
			-o-transition: color 0.15s ease-out;
			-ms-transition: color 0.15s ease-out;
			transition: color 0.15s ease-out;
		}
		a:hover {
			text-decoration: none;
			color:#008268;
		}

		form,
		input,
		textarea {
			margin: 0;
			padding: 0;
			outline: none;
			-webkit-border-radius: 0;
		}
		/* SEARCH FIELD PLACEHOLDER - STANDARD */
		::-webkit-input-placeholder {
		/* WebKit browsers */
			padding-top: 2px;
			color: #aaa;
		}
		:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
			padding-top: 2px;
			color: #aaa;
		}
		::-moz-placeholder {
		/* Mozilla Firefox 19+ */
			padding-top: 2px;
			color: #aaa;
		}
		:-ms-input-placeholder {
		/* Internet Explorer 10+ */
			padding-top: 2px;
			color: #aaa;
		}
		label.placeholder {
		/* Internet Explorer 9- */
			color: #aaa !important;
		}

		/* SEARCH FIELD PLACEHOLDER - MOBILE */
		.topMobile-search ::-webkit-input-placeholder {
		/* WebKit browsers */
			color: #02649f;
		}
		.topMobile-search :-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
			color: #02649f;
		}
		.topMobile-search ::-moz-placeholder {
		/* Mozilla Firefox 19+ */
			color: #02649f;
		}
		.topMobile-search :-ms-input-placeholder {
		/* Internet Explorer 10+ */
			color: #02649f;
		}
		.topMobile-search label.placeholder {
		/* Internet Explorer 9- */
			color: #02649f !important;
		}

		blockquote {
			width: 100%;
			padding: 30px 40px 10px 40px;
			margin: 0 0 30px 30px;
			border: 4px solid #cde5f3;
			background: #fff;
		}
		blockquote .button,
		blockquote .button-light-blue,
		blockquote .button-dark-blue {
			margin: 0 0 30px 0;
		}
		blockquote h2 {
			margin-bottom: 25px;
		}

		sup,
		sub {
			font-size: 12px;
			line-height: 12px;
		}

@media print {
  @page {
    size: 370mm 427mm;
    margin: 14mm;
  }
}

`;

export default GlobalStyles;
